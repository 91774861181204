import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax'

import MainContainer from "components/layout/MainContainer";

import { routes } from "utils/constants";

let router = createBrowserRouter(routes);

function App() {
  return (
    <ParallaxProvider>
      <MainContainer>
        <RouterProvider
          router={router}
          fallbackElement={<p>Loading...</p>}
        />
      </MainContainer>
    </ParallaxProvider>
  );
}

export default App;
