import React from 'react'

function TwitterIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.7293 38.1366C43.7293 39.768 43.092 41.3325 41.9575 42.486C40.823 43.6396 39.2843 44.2877 37.6799 44.2877H6.2231C4.6187 44.2877 3.08001 43.6396 1.94553 42.486C0.811051 41.3325 0.173706 39.768 0.173706 38.1366V6.15106C0.173706 4.5197 0.811051 2.95515 1.94553 1.8016C3.08001 0.648057 4.6187 0 6.2231 0H37.6799C39.2843 0 40.823 0.648057 41.9575 1.8016C43.092 2.95515 43.7293 4.5197 43.7293 6.15106V38.1366Z" fill="black" />
            <path d="M30.3644 17.1173C29.746 17.4054 28.9628 17.6758 28.2609 17.7657C28.9747 17.3207 30.1071 16.3937 30.3644 15.5552C29.6976 15.9671 28.4916 16.407 27.7049 16.5661C27.0766 15.8661 26.1805 15.5552 25.1876 15.5552C23.2804 15.5552 21.9503 17.2536 21.9503 19.2394V20.7131C19.1456 20.7131 16.411 18.4679 14.7092 16.292C14.4015 16.8404 14.24 17.4658 14.2415 18.1024C14.2415 19.4428 15.4132 20.803 16.3409 21.4499C15.775 21.4315 14.7036 20.9776 14.2373 20.7131V20.7551C14.2373 22.4992 15.402 23.6833 16.9803 24.0134C16.6921 24.0967 16.3409 24.3973 14.989 24.3973C15.4279 25.8231 17.6345 26.5769 19.1456 26.6078C17.9634 27.5709 15.8556 28.0815 14.2373 28.0815C13.9575 28.0815 13.8061 28.0977 13.5361 28.0646C15.0633 29.0814 17.1963 29.5552 19.1456 29.5552C25.4962 29.5552 28.9621 24.4577 28.9621 19.7036C28.9621 19.5474 28.9572 19.0242 28.9495 18.871C29.6282 18.3684 29.9031 17.8423 30.3644 17.1173Z" fill="white" />
        </svg>
    )
}

export default TwitterIcon