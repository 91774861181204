import React, { useState, useEffect } from 'react'
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax'
import { useSelector } from 'react-redux'

import AboutUsBanner1 from 'assets/images/about-us/about-us-banner-1.png'
import AboutUsBanner2 from 'assets/images/about-us/about-us-banner-2.jpg'
import AboutUsIcon from 'assets/icons/AboutUsIcon'
import Newsletter from 'components/newsletter/Newsletter'

function About() {

  const storedLanguage = localStorage.getItem("language")
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  useEffect(() => {
    if (storedLanguage === 'en') {
      setSelectedLanguage('en')
    }
    if (storedLanguage === 'id') {
      setSelectedLanguage('id')
    }
  }, [storedLanguage]);

  const innerWidth = window.innerWidth

  return (
    <div className='flex-1'>
      <section>
        <div className='mx-auto px-6 text-justify sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-4xl 2xl:max-w-6xl py-8 xl:py-12 space-y-4 lg:space-y-6 app-animation-translate-y-start'>
          <div>
            <p className='text-xl sm:text-3xl lg:text-4xl font-bold text-indigo-600 app-animation-translate-y-start'>
              {selectedLanguage == 'en' && 'About Us'}
              {selectedLanguage == 'id' && 'Tentang Kami'}
            </p>
          </div>
          <div className='text-sm sm:text-sm lg:text-base space-y-6 app-animation-translate-y-start'>
            {
              selectedLanguage == 'en' && (
                <>
                  <p>The ESG Task Force is part of the Sustainability Working Group formed by KADIN Indonesia since 2022. The ESG Task Force was formed with the aim of providing guidance and inspiration regarding Environmental, Social and Governance (ESG) to business actors in Indonesia.</p>
                  <p>The main task of the ESG Task Force involves the preparation of General ESG Guidelines (ESG 101) which apply to all types of industry without exception. This guide is presented in various forms, including E-books, infographics, videos, podcasts, and others, while always following developments in world agreements and Indonesian Government regulations.</p>
                  <p>The ESG Task Force invites all business actors in Indonesia to share their experiences in implementing ESG. The hope is that all business actors in Indonesia can implement ESG well, contributing to the environment, society and business sustainability.</p>
                </>
              )
            }
            {
              selectedLanguage == 'id' && (
                <>
                  <p>ESG Task Force merupakan bagian dari Pokja Sustainability yang dibentuk oleh KADIN Indonesia sejak tahun 2022. ESG Task Force dibentuk dengan tujuan memberikan panduan dan inspirasi mengenai Environmental, Social, and Governance (ESG) kepada pelaku usaha di Indonesia.</p>
                  <p>Tugas pokok ESG Task Force melibatkan penyusunan Panduan ESG Umum (ESG 101) yang berlaku untuk semua jenis industri tanpa terkecuali. Panduan ini disajikan dalam berbagai bentuk, termasuk E-book, infografis, video, podcast, dan lainnya, dengan senantiasa mengikuti perkembangan kesepakatan dunia dan peraturan Pemerintah Indonesia.</p>
                  <p>ESG Task Force mengundang semua pelaku usaha di Indonesia untuk membagikan pengalaman mereka dalam menerapkan ESG. Harapannya adalah agar semua pelaku usaha di Indonesia dapat menjalankan ESG dengan baik, memberikan kontribusi kepada lingkungan, masyarakat, dan keberlanjutan usaha.</p>
                </>
              )
            }
          </div>
        </div>
      </section>

      {innerWidth < 640 ? (
        <section className='grid grid-cols-1 sm:grid-cols-2'>
          <div className=''>
            <a href="/" target='_blank' rel="noopener noreferrer">
              <img
                src={AboutUsBanner2}
                alt="item-1"
                className='w-full object-cover max-h-[70vh] app-animation-translate-x-start-left'
              />
            </a>
          </div>
          <div className='bg-indigo-50 py-8 sm:py-12 px-10 sm:px-32 flex items-center justify-end'>
            <div className='max-w-2xl space-y-4 sm:space-y-8'>
              <p className='text-3xl sm:text-6xl font-medium text-indigo-600 app-animation-translate-x-start-left'>
                Thinking Beyond ESG
              </p>
              <p className='text-base sm:text-lg app-animation-translate-x-start-left'>
                In this innovative paradigm, we delve into the intricacies of sustainability, ethical practices, and corporate responsibility with a heightened awareness of emerging global challenges. 
              </p>
            </div>
          </div>
        </section>
      ) : (
        <section className='grid grid-cols-1 sm:grid-cols-2'>
          <div className='bg-indigo-50 py-8 sm:py-10 px-10 lg:px-20 2xl:px-32 flex items-center justify-end'>
            <div className='max-w-2xl space-y-4 lg:space-y-8'>
              <p className='text-4xl sm:text-xl md:text-2xl lg:text-4xl 2xl:text-5xl font-medium text-indigo-600 app-animation-translate-x-start-left'>
                {selectedLanguage == 'en' && 'Thinking Beyond ESG'}
                {selectedLanguage == 'id' && 'Berpikir Melampaui ESG'}
              </p>
              <p className='text-base sm:text-xs lg:text-base 2xl:text-lg app-animation-translate-x-start-left'>
                {selectedLanguage == 'en' && 'In this innovative paradigm, we delve into the intricacies of sustainability, ethical practices, and corporate responsibility with a heightened awareness of emerging global challenges.'}
                {selectedLanguage == 'id' && 'Dalam paradigma inovatif ini, kami mendalami seluk-beluk keberlanjutan, praktik etis, dan tanggung jawab perusahaan dengan meningkatkan kesadaran akan tantangan global yang muncul.'}
              </p>
            </div>
          </div>
          <div className=''>
            <a href="/" target='_blank' rel="noopener noreferrer">
              <img
                src={AboutUsBanner2}
                alt="item-1"
                className='w-full object-cover max-h-[70vh] app-animation-translate-x-start-left'
              />
            </a>
          </div>
        </section>
      )}

      {/* <section >
                <img className='w-full max-h-[60vh] app-animation-scale-start' src={AboutUsBanner1} alt="item-1" />
            </section> */}

      <ParallaxBanner style={{ aspectRatio: '3.25' }}>
        <ParallaxBannerLayer image={AboutUsBanner1} speed={-80} style={{ backgroundSize: "contain" }} />
      </ParallaxBanner>

      {/* <section >
        <div className='mx-auto px-6 sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-4xl 2xl:max-w-6xl py-10 xl:py-14 space-y-4 lg:space-y-8 app-animation-translate-y-start'>
          <div className='flex flex-col gap-6'>
            <AboutUsIcon className="fill-white h-14 w-14 sm:h-18 sm:w-18 lg:h-20 lg:w-20" />
            <p className='text-2xl sm:text-3xl xl:text-4xl font-bold app-animation-translate-y-start'>Our purpose and value</p>
          </div>
          <div>
            <p className='text-base sm:text-lg lg:text-xl text-justify font-semibold app-animation-translate-y-start'>
              Lorem ipsum dolor sit amet, lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.
            </p>
          </div>
          <div>
            <p className='text-sm lg:text-base text-justify app-animation-translate-y-start'>
              Lorem ipsum dolor sit amet consectetur. Volutpat feugiat sed in dolor faucibus cras vestibulum ut. Leo platea risus tellus quis senectus. Diam accumsan ullamcorper pellentesque ligula. Urna etiam donec nisi id.Quam quam neque tellus quam purus. Ipsum amet praesent egestas consequat at iaculis eu dictum. Sapien libero morbi vulputate est nam nulla et. Amet eu hendrerit fringilla volutpat justo arcu pretium at sit. Augue adipiscing diam nulla aenean sem felis diam vestibulum. Risus urna est amet turpis consequat volutpat massa orci sed. Suspendisse tortor hac faucibus cursus sed nec mauris nunc.
            </p>
          </div>
        </div>
      </section> */}

      <Newsletter />
    </div >
  )
}

export default About