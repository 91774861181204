import { createStore } from 'redux'

const initialState = {
  user: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'SET_USER':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
