import React, { useState, useEffect } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import CloseIcon from 'assets/icons/CloseIcon';
import Banner3 from 'assets/images/landing-page/banner-3.png'
import useFetch from 'utils/hooks/useFetch';
import SelectInput from 'components/input/SelectInput'
import { SEND_DOWNLOAD_NOTICE_TO_ADMIN } from 'api';

function DownloadConfirmation({
    setOpenDownloadConfirmation,
    caseName,
    title
}) {
    const [isDownloadSuccess, setIsDownloadSuccess] = useState(false)
    const storedLanguage = localStorage.getItem("language")
    const [selectedLanguage, setSelectedLanguage] = useState('en')
    const { fetch: sendDownloadNoticeToAdmin } = useFetch(SEND_DOWNLOAD_NOTICE_TO_ADMIN)
    const formik = useFormik({
        initialValues: {
            name: '',
            company_name: '',
            email_address: '',
            phone_number: '',
            is_kadin_member: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(selectedLanguage === 'en' ? "Please enter your full name" : selectedLanguage === 'id' ? "Masukkan nama lengkap Anda" : "Please enter your full name"),
            company_name: Yup.string().required(selectedLanguage === 'en' ? "Please enter your company name" : selectedLanguage === 'id' ? "Masukkan nama perusahaan Anda" : "Please enter your company name"),
            email_address: Yup.string().required(selectedLanguage === 'en' ? "Please enter your email address" : selectedLanguage === 'id' ? "Masukkan alamat email Anda" : "Please enter your email address").email('Invalid email format'),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            console.log({
              full_name: values.name,
              company_name: values.company_name,
              email_address: values.email_address,
              phone_number: values.phone_number,
              is_kadin_member: values.is_kadin_member,
              case_name: caseName
          })
            const newDownloadCase = await sendDownloadNoticeToAdmin({
                data: {
                    full_name: values.name,
                    company_name: values.company_name,
                    email_address: values.email_address,
                    phone_number: values.phone_number,
                    is_kadin_member: values.is_kadin_member,
                    case_name: caseName
                }
            })
            if (newDownloadCase.success) {
                setIsDownloadSuccess(true)
                // setOpenDownloadConfirmation({ show: false, case: '' })
            }
        },
    });

    useEffect(() => {
        if (storedLanguage === 'en') {
            setSelectedLanguage('en')
        }
        if (storedLanguage === 'id') {
            setSelectedLanguage('id')
        }
    }, [storedLanguage]);

    const handleCancel = () => {
        setOpenDownloadConfirmation({ show: false, case: '', title: '' })
        setIsDownloadSuccess(false)
    }

    return (
        <div className='fixed top-0 lg:top-20 bottom-0 right-0 left-0 z-50 flex justify-center items-center'>
            {isDownloadSuccess ? (
                <div className='flex flex-col rounded-xl fixed bg-white z-50 sm:mb-4 md:mb-20 mx-4 max-w-xs sm:max-w-lg lg:max-w-3xl lg:max-h-[90vh] text-left py-4 pb-8 sm:px-12 sm:py-6 sm:pb-12 space-y-4 sm:space-y-8'>
                    <div className='mt-2 mr-5 sm:mr-0 flex justify-end'>
                        <button onClick={handleCancel}><CloseIcon /></button>
                    </div>
                    <p className='font-semibold text-center text-base sm:text-2xl lg:text-3xl px-4 sm:px-0'>Thank you for downloading</p>
                    <p className='font-medium text-center text-xs sm:text-base lg:text-xl px-4 sm:px-0'>Thank you for requesting to read our case study. <br /> We have sent you email.</p>
                </div>
            ) : (
                <div className='flex flex-col rounded-xl fixed bg-white z-50 sm:mb-4 md:mb-20 mx-4 max-w-xs sm:max-w-lg lg:max-w-3xl lg:max-h-[90vh] text-left py-4 pb-8 sm:px-12 sm:py-6 sm:pb-12 space-y-6 sm:space-y-8'>
                    <div className='mt-2 mr-5 sm:mr-0 flex justify-end'>
                        <button onClick={handleCancel}><CloseIcon /></button>
                    </div>
                    <p className='font-semibold text-center text-lg sm:text-2xl lg:text-4xl px-4 sm:px-0'>Download Case Study</p>
                    <div className='flex flex-col text-center md:flex-row md:text-start px-10 font-medium gap-4 sm:gap-6 lg:gap-8'>
                        <div className='flex justify-center items-center'>
                            <img
                                src={Banner3}
                                alt="item-1"
                                className='w-full md:max-w-xs object-cover max-h-[80vh]'
                            />
                        </div>
                        <div className='flex justify-center items-center'>
                            <p className='text-sm sm:text-base lg:text-2xl'>
                                {title}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col px-10 space-y-4 overflow-auto'>
                        <div>
                            <input
                                id='name'
                                name='name'
                                className='w-full text-xs sm:text-sm lg:text-base border border-indigo-600 rounded-xl px-2 py-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                                placeholder={selectedLanguage === 'en' ? "Please enter your full name..." : selectedLanguage === 'id' ? "Masukkan nama lengkap Anda..." : "Please enter your full name..."}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            {formik.errors.name && (
                                <p className='ml-2 mt-3 text-xs sm:text-sm text-app-brown'>{formik.errors.name}</p>
                            )}
                        </div>
                        <div>
                            <input
                                id='company_name'
                                name='company_name'
                                className='w-full text-xs sm:text-sm lg:text-base border border-indigo-600 rounded-xl px-2 py-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                                placeholder={selectedLanguage === 'en' ? "Please enter your company name..." : selectedLanguage === 'id' ? "Masukkan nama perusahaan Anda..." : "Please enter your company name..."}
                                onChange={formik.handleChange}
                                value={formik.values.company_name}
                            />
                            {formik.errors.company_name && (
                                <p className='ml-2 mt-3 text-xs sm:text-sm text-app-brown'>{formik.errors.company_name}</p>
                            )}
                        </div>
                        <div>
                            <input
                                id='email_address'
                                name='email_address'
                                className='w-full text-xs sm:text-sm lg:text-base border border-indigo-600 rounded-xl px-2 py-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                                placeholder={selectedLanguage === 'en' ? "Please enter your email address..." : selectedLanguage === 'id' ? "Masukkan alamat email Anda..." : "Please enter your email address..."}
                                onChange={formik.handleChange}
                                value={formik.values.email_address}
                            />
                            {formik.errors.email_address && (
                                <p className='ml-2 mt-3 text-xs sm:text-sm  text-app-brown'>{formik.errors.email_address}</p>
                            )}
                        </div>
                        {/* <div>
                            <input
                                id='phone_number'
                                name='phone_number'
                                className='w-full text-xs sm:text-sm lg:text-base border border-indigo-600 rounded-xl px-2 py-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                                placeholder={selectedLanguage === 'en' ? "Please enter your phone number..." : selectedLanguage === 'id' ? "Masukkan nomor telepon Anda..." : "Please enter your phone number..."}
                                onChange={formik.handleChange}
                                value={formik.values.phone_number}
                            />
                            {formik.errors.phone_number && (
                                <p className='ml-2 mt-3 text-xs sm:text-sm text-app-brown'>{formik.errors.phone_number}</p>
                            )}
                        </div> */}
                        <div>
                            <SelectInput
                                name="is_kadin_member"
                                placeholder={selectedLanguage === 'en' ? "Are you Kadin Member?" : selectedLanguage === 'id' ? "Apakah Anda anggota Kadin?" : "Are you Kadin Member?"}
                                classNameInput="py-3"
                                options={[{
                                    id: "Yes",
                                    text: 'Yes'
                                }, {
                                    id: "No",
                                    text: 'No'
                                }]}
                                value={formik.values.is_kadin_member}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className='text-center lg:text-left'>
                            <button
                                onClick={formik.handleSubmit}
                                className='text-xs sm:text-sm sm lg:text-base px-3 py-2 sm:px-5 sm:py-3 lg:px-6 bg-indigo-900 text-white rounded-full'
                            >
                                {selectedLanguage === 'en' && (
                                    <>
                                        Download
                                    </>
                                )}
                                {selectedLanguage === 'id' && (
                                    <>
                                        Unduh
                                    </>
                                )}
                            </button>
                        </div>
                    </div>

                </div>
            )}
            <div className='fixed top-0 bottom-0 right-0 left-0 z-40 bg-app-grey/50 blur-lg' />
        </div>
    )
}

export default DownloadConfirmation