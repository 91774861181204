import React from 'react'

function CloseIcon({
    className = "h-5 w-5 fill-black",
    stroke = "black"
}) {
    return (
        <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L27 27M2 27L27 2" stroke={stroke} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CloseIcon