import React, { useState } from 'react'
import CloseIcon from 'assets/icons/CloseIcon'
import SearchIcon from 'assets/icons/SearchIcon'
import { resources } from 'utils/constants/data'

function Search({
    isOpen,
    onClose,
}) {
    const [searchQuery, setSearchQuery] = useState("")

    const handleChangeSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    const searchResults = resources?.filter(item => item.title.includes(searchQuery))

    return (
        <div className={`md:hidden fixed top-0 bottom-0 left-0 right-0 bg-black/80 backdrop-blur-lg ${isOpen ? "inline-block" : "hidden"} z-50`}>
            <div className='app-nav-container flex flex-col gap-6'>
                <div className='text-right font-medium'>
                    <button className='p-2 text-sm active:border-b-2 active:border-black transition-all' onClick={onClose}>
                        <CloseIcon className="h-5 w-5 fill-white" stroke='white' />
                    </button>
                </div>
                <div className={`flex flex-col justify-between flex-1`}>
                    <div className='flex  items-center gap-2 border border-white rounded-md px-3 py-2'>
                        <input
                            className='flex-1 bg-transparent outline-none text-sm text-white placeholder-white/50'
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={handleChangeSearch}
                        />
                        <button>
                            <SearchIcon className='h-5 w-5' fill="white" />
                        </button>
                    </div>
                    {searchQuery ? (
                        <div className='mt-6 bg-transparent max-h-[75vh] overflow-y-auto flex flex-col gap-6 pb-5'>
                            {searchResults.map((item, i) => (
                                <a href={`/articles/${item.id}`} className='space-y-2'>
                                    <p className='text-white text-xs sm:text-sm font-bold'>{item.title}</p>
                                    <p className='text-white text-xs sm:text-sm'>{item.description}</p>
                                </a>
                            ))}
                        </div>
                    ) : (
                        <div className='mt-12 bg-transparent text-white max-h-[80vh] text-xs sm:text-sm pb-5 flex items-center justify-center text-app-light-grey-2'>
                            No recent searches
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Search
