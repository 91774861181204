import React from 'react'

function ArrowLeftIcon({
    className = "h-3 w-3 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5518 1.89647L2 10.4482L10.5518 19M3.18775 10.4482H20.5288" stroke="black" stroke-width="2.85059" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ArrowLeftIcon