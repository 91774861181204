import React, { useEffect } from 'react'
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax'
import { useSelector } from 'react-redux'
import moment from 'moment'

import ArticlesBanner from 'assets/images/blog-page/article-detail.png'
import ArticleItem1 from 'assets/images/blog-page/article-item-1.png'
import ArticleItem2 from 'assets/images/blog-page/article-item-2.jpg'
import ArticleItem3 from 'assets/images/blog-page/article-item-3.jpg'
import ArticleItem4 from 'assets/images/blog-page/article-item-4.jpg'
import ArticleItem5 from 'assets/images/blog-page/article-item-5.jpg'
import ArticleItem6 from 'assets/images/blog-page/article-item-6.jpg'
import ArticleItem7 from 'assets/images/blog-page/article-item-7.jpg'
import Newsletter from 'components/newsletter/Newsletter';

import useFetch from 'utils/hooks/useFetch';

function Articles() {
    const articles = [
        {
            id: 1,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 2,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 3,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 4,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 5,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 6,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 7,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 8,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
        {
            id: 9,
            title: 'Five ways that ESG creates value',
            desc: 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.',
            date: moment().format("DD MMMM YYYY")
        },
    ]

    return (
        <div className='flex-1'>

            {/* <section>
                <div className="relative isolate overflow-hidden py-10 sm:py-16 lg:py-20 xl:py-28">
                    <img
                        src={ArticlesBanner}
                        alt="main-banner"
                        className="absolute inset-0 -z-10 h-full w-full brightness-75 object-cover object-right md:object-center app-animation-opacity-start"
                    />
                    <div className="mx-auto lg:max-w-xl xl:max-w-3xl text-center px-6 lg:px-8">
                        <div className="mx-auto max-w-[12rem] sm:max-w-xs lg:max-w-xl xl:max-w-3xl lg:mx-0 space-y-8  app-animation-opacity-start">
                            <p className="text-base sm:text-2xl xl:text-6xl  font-bold text-white md:leading-relaxed lg:leading-relaxed xl:leading-relaxed">
                                Read our articles and newest updates
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}

            <ParallaxBanner style={{ aspectRatio: '4' }}>
                <ParallaxBannerLayer image={ArticlesBanner} speed={-100} style={{ backgroundSize: "contain" }} />
                <ParallaxBannerLayer>
                    <div className="mx-auto flex h-full place-items-center lg:max-w-xl xl:max-w-3xl">
                        <div className="mx-auto flex h-full max-w-[12rem] place-items-center sm:max-w-xs lg:max-w-xl xl:max-w-3xl lg:mx-0">
                            <p className='text-base sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold text-center text-white md:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed'>
                                Read our articles and newest updates
                            </p>
                        </div>
                    </div>
                </ParallaxBannerLayer>
            </ParallaxBanner>

            <section>
                <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-6 lg:py-12 xl:py-22 max-w-[20rem] sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl lg:px-8">
                    <h2 className="text-base sm:text-xl lg:text-2xl xl:text-4xl text-center font-bold text-indigo-600 app-animation-translate-y-start">Resources and Articles</h2>
                    <div className="mt-4 sm:mt-8 lg:mt-14 xl:mt-16 grid gap-x-6 gap-y-2 md:gap-y-6 lg:gap-y-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:gap-x-8">
                        {articles.map((article) => (
                            <div key={article.id} className="group relative">
                                <div className="aspect-h-1 aspect-w-1 h-full w-full max-h-32 sm:max-h-40 lg:max-h-48 xl:max-h-64 overflow-hidden bg-gray-200 lg:aspect-none group-hover:opacity-75">
                                    <a href={`/articles/${article.id}`}>
                                        <img
                                            src={
                                                article.id == 1 ? ArticleItem1
                                                    : article.id == 2 ? ArticleItem2
                                                        : article.id == 3 ? ArticleItem3
                                                            : article.id == 4 ? ArticleItem4
                                                                : article.id == 5 ? ArticleItem5
                                                                    : article.id == 6 ? ArticleItem6
                                                                        : article.id == 7 ? ArticleItem7
                                                                            : article.id == 8 ? ArticleItem1
                                                                                : article.id == 9 ? ArticleItem2
                                                                                    : ArticleItem3
                                            }
                                            alt="podcast-item"
                                            className="h-full w-full max-h-40 sm:max-h-40 lg:max-h-48 xl:max-h-64 object-cover object-center app-animation-scale-start"
                                        />
                                    </a>
                                </div>
                                <div className="bottom-0 px-2 py-4 space-y-1 sm:space-y-2 lg:space-y-4">
                                    <div>
                                        <p className="text-[0.7rem] sm:text-sm lg:text-base xl:text-xl text-black font-bold app-animation-translate-y-start">
                                            <a href={`/articles/${article.id}`}>
                                                <span aria-hidden="true" className="absolute inset-0" />
                                                {article.title}
                                            </a>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-[0.5rem] sm:text-xs lg:text-sm xl:text-base text-black app-animation-translate-y-start">{article.desc}</p>
                                    </div>
                                    <div>
                                        <p className="text-[0.5rem] sm:text-xs lg:text-sm xl:text-base text-gray-500 app-animation-translate-y-start">{article.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='text-center'>
                        <button
                            onClick={() => console.log("Load More")}
                            className='text-[0.5rem] sm:text-sm lg:text-sm xl:text-base mt-4 sm:mt-8 px-10 py-2 sm:px-22 lg:px-28 sm:py-3 border border-black bg-white text-black font-medium text-center rounded-full app-animation-translate-y-start-'
                        >
                            Load More
                        </button>
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default Articles
