import React from 'react'

function LinkedinIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.0594 0H10.6528C5.16282 0 0.71228 4.45054 0.71228 9.94056V34.3471C0.71228 39.8371 5.16282 44.2877 10.6528 44.2877H35.0594C40.5494 44.2877 44.9999 39.8371 44.9999 34.3471V9.94056C44.9999 4.45054 40.5494 0 35.0594 0Z" fill="black" />
            <path d="M17.6838 19.1568H14.1401V29.5347H17.6838V19.1568Z" fill="white" />
            <path d="M17.088 14.3137C16.7503 14.1091 16.3534 14 15.9474 14C15.6765 14 15.4082 14.0486 15.1581 14.143C14.9079 14.2374 14.6808 14.3758 14.4898 14.5502C14.2988 14.7245 14.1477 14.9314 14.0451 15.159C13.9425 15.3866 13.8904 15.6303 13.892 15.8762C13.8943 16.2447 14.0169 16.6043 14.2441 16.9096C14.4714 17.215 14.7932 17.4524 15.169 17.592C15.5447 17.7315 15.9576 17.7669 16.3556 17.6937C16.7535 17.6205 17.1186 17.442 17.4049 17.1807C17.6911 16.9194 17.8857 16.587 17.9641 16.2255C18.0424 15.8639 18.0011 15.4894 17.8451 15.1491C17.6892 14.8089 17.4258 14.5182 17.088 14.3137Z" fill="white" />
            <path d="M23.3722 19.1563H19.9584L19.9466 29.5556H23.4903V24.4202C23.4903 23.0694 23.7738 21.7614 25.6166 21.7614C27.4593 21.7614 27.4593 23.316 27.4593 24.5167V29.5341H31.0031V23.8413C31.0031 21.0431 30.2825 18.899 26.7388 18.899C26.0634 18.875 25.3935 19.0179 24.8005 19.3124C24.2076 19.607 23.7139 20.0421 23.3722 20.5714V19.1563Z" fill="white" />
        </svg>
    )
}

export default LinkedinIcon