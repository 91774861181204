import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import ActivityIcon from 'assets/icons/newsletter/ActivityIcon'
import PieChartIcon from 'assets/icons/newsletter/PieChartIcon'
import EngagementIcon from 'assets/icons/newsletter/EngagementIcon'

function Newsletter() {

  const storedLanguage = localStorage.getItem("language")
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  useEffect(() => {
    if (storedLanguage === 'en') {
      setSelectedLanguage('en')
    }
    if (storedLanguage === 'id') {
      setSelectedLanguage('id')
    }
  }, [storedLanguage]);

  const formik = useFormik({
    initialValues: {
      email_subscriber: ''
    },
    validationSchema: Yup.object().shape({
      email_subscriber: Yup.string().required('Please input your email address').email('Invalid email format')
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      window.location.reload()
    },
  });

  return (
    <section className='bg-app-cream'>
      <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-2'>
        <div className='w-3/4 mx-auto py-4 sm:px-4 sm:py-1 xl:px-1 flex flex-col justify-center items-center sm:items-start gap-4 sm:gap-6 app-animation-translate-y-start'>
          <p className='text-sm text-center sm:text-left sm:text-lg lg:text-2xl xl:text-4xl text-indigo-600 sm:text-5xl font-semibold app-animation-translate-y-start'>
            {selectedLanguage == 'en' && 'Join Our ESG Community'}
            {selectedLanguage == 'id' && 'Bergabunglah dengan Komunitas ESG Kami'}
          </p>
          <p className='text-xs text-center sm:text-left sm:text-sm xl:text-base font-medium app-animation-translate-y-start'>
            {selectedLanguage == 'en' && 'Join us in fostering positive change by becoming a part of a dynamic network dedicated to driving environmental consciousness, promoting social responsibility, and upholding strong governance principles.'}
            {selectedLanguage == 'id' && 'Bergabunglah bersama kami dalam mendorong perubahan positif dengan menjadi bagian dari jaringan dinamis yang didedikasikan untuk mendorong kesadaran lingkungan, mendorong tanggung jawab sosial, dan menjunjung tinggi prinsip-prinsip tata kelola yang kuat.'}
          </p>
          <div className='text-sm sm:text-base flex flex-col sm:flex-row gap-2 sm:gap-4 app-animation-translate-y-start'>
            <div className='flex flex-col lg:flex-row lg:gap-4 lg:justify-center lg:items-center lg space-y-2'>
              {/* <div className='flex flex-row lg:flex-col'>
                <input
                  id='email_subscriber'
                  name='email_subscriber'
                  className='text-xs w-56 xl:text-sm sm:w-60 xl:w-72 border border-indigo-600 rounded-xl px-2 sm:px-4 py-2 sm:py-3'
                  placeholder={selectedLanguage == 'en' ? 'Please input your email address' : 'Masukkan alamat email anda'}
                  onChange={formik.handleChange}
                  value={formik.values.email_subscriber}
                />
                {formik.errors.email_subscriber && (
                  <p className='pl-2 pt-2 text-xs xl:text-sm text-app-brown'>{formik.errors.email_subscriber}</p>
                )}
              </div> */}

              <div className='flex flex-col'>
                {/* <button
                  onClick={formik.handleSubmit}
                  className='text-xs xl:text-sm px-2 py-2 sm:px-4 sm:py-3 lg:mb-2 bg-indigo-800 text-white rounded-xl'
                >
                  {selectedLanguage == 'en' && 'Subscribe'}
                  {selectedLanguage == 'id' && 'Berlangganan'}
                </button> */}
                <button
                  onClick={() => window.location.href = '/contact-us'}
                  className='text-xs xl:text-sm px-2 py-2 sm:px-4 sm:py-3 lg:mb-2 bg-indigo-800 text-white rounded-xl'
                >
                  {selectedLanguage == 'en' && 'Contact us'}
                  {selectedLanguage == 'id' && 'Hubungi kami'}
                </button>
              </div>
            </div>

          </div>
        </div>
        <div className='w-3/4 py-4 sm:px-4 sm:py-1 mx-auto flex flex-col justify-center gap-6 app-animation-translate-y-start'>
          <div className='flex flex-row gap-6 items-center'>
            <div className='bg-slate-50 shadow-md px-4 py-4 sm:px-4 sm:py-4 xl:px-6 xl:py-6'>
              <div className='py-1 px-2 font-bold text-yellow-600 text-xl'>
                E
              </div>
            </div>
            <div>
              <p className='text-sm lg:text-base xl:text-lg text-indigo-600 font-bold app-animation-translate-y-start'>
                Environmental
              </p>
              <p className='text-xs lg:text-sm  font-medium app-animation-translate-y-start'>
                {selectedLanguage == 'en' && 'The "E" in ESG refers to environmental factors that organizations consider in their operations and decision-making processes.'}
                {selectedLanguage == 'id' && 'Huruf “E” dalam ESG mengacu pada faktor lingkungan yang dipertimbangkan organisasi dalam operasi dan proses pengambilan keputusannya.'}
              </p>
            </div>
          </div>
          <div className='flex flex-row gap-6 items-center'>
            <div className='bg-slate-50 shadow-md px-4 py-4 sm:px-4 sm:py-4 xl:px-6 xl:py-6'>
              <div className='py-1 px-2 font-bold text-yellow-600 text-xl'>
                S
              </div>
            </div>
            <div>
              <p className='text-sm lg:text-base xl:text-lg text-indigo-600 font-bold app-animation-translate-y-start'>
                Social
              </p>
              <p className='text-xs lg:text-sm  font-medium app-animation-translate-y-start'>
                {selectedLanguage == 'en' && `The "S" in ESG stands for social factors, emphasizing the impact of a company's activities on society.`}
                {selectedLanguage == 'id' && 'Huruf “S” dalam ESG berarti faktor sosial, yang menekankan dampak aktivitas perusahaan terhadap masyarakat.'}
              </p>
            </div>
          </div>
          <div className='flex flex-row gap-6 items-center'>
            <div className='bg-slate-50 shadow-md px-4 py-4  sm:px-4 sm:py-4 xl:px-6 xl:py-6'>
              <div className='py-1 px-2 font-bold text-yellow-600 text-xl'>
                G
              </div>
            </div>
            <div>
              <p className='text-sm lg:text-base xl:text-lg text-indigo-600 font-bold app-animation-translate-y-start'>
                Governance
              </p>
              <p className='text-xs lg:text-sm font-medium app-animation-translate-y-start'>
                {selectedLanguage == 'en' && `Governance, represented by the "G" in ESG, focuses on the systems and structures that guide a company's decision-making and oversight.`}
                {selectedLanguage == 'id' && 'Tata Kelola, yang diwakili oleh huruf “G” dalam ESG, berfokus pada sistem dan struktur yang memandu pengambilan keputusan dan pengawasan perusahaan.'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter