export const resources = [
    {
        id: "1",
        title: "How to pioneering in Sustainability 1",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "2",
        title: "Sustainability Education",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "3",
        title: "How to pioneering in Sustainability 2",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "1",
        title: "How to pioneering in Sustainability 1",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "2",
        title: "Sustainability Education",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "3",
        title: "How to pioneering in Sustainability 2",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "1",
        title: "How to pioneering in Sustainability 1",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "2",
        title: "Sustainability Education",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "3",
        title: "How to pioneering in Sustainability 2",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "1",
        title: "How to pioneering in Sustainability 1",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "2",
        title: "Sustainability Education",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "3",
        title: "How to pioneering in Sustainability 2",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
]
