import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie'

import Navbar from './Navbar'
import Footer from './Footer'
import Menu from 'components/menu/Menu'
import Search from 'components/menu/Search';
import useAnimation from 'utils/hooks/useAnimation'
import useFetch from 'utils/hooks/useFetch'

function MainContainer({ children }) {
    useAnimation();
    const dispatch = useDispatch();
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isSearchOpen, setSearchOpen] = useState(false)
    const [isEnglish, setIsEnglish] = useState(true)

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("language")
        if (selectedLanguage === "en") {
            setIsEnglish(true)
        }
        if (selectedLanguage === "id") {
            setIsEnglish(false)
        }
    }, []);

    const handleSwitchLanguage = () => {
        setIsEnglish((prev) => !prev)
        if (isEnglish === true) {
            localStorage.setItem("language", "id")
        }
        if (isEnglish === false) {
            localStorage.setItem("language", "en")
        }
        window.scrollTo(0, 0);
        window.location.reload(true)
    }

    // useEffect(() => {
    //     if (isEnglish === true) {
    //         localStorage.setItem("language", "en")
    //     }
    //     if (isEnglish === false) {
    //         localStorage.setItem("language", "id")
    //     }
    // }, [isEnglish]);

    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar
                openMenu={() => setMenuOpen(true)}
                openSearchPage={() => setSearchOpen(true)}
            />
            {children}
            <Footer
                isEnglish={isEnglish}
                setIsEnglish={setIsEnglish}
                handleSwitchLanguage={handleSwitchLanguage}
            />
            <Menu
                isOpen={isMenuOpen}
                onClose={() => setMenuOpen(false)}
            />
            <Search
                isOpen={isSearchOpen}
                onClose={() => setSearchOpen(false)}
            />
        </div>
    )
}

export default MainContainer
