import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"
import ReCAPTCHA from "react-google-recaptcha";
import useFetch from 'utils/hooks/useFetch';

import TextAreaInput from 'components/input/TextAreaInput'
import TextInput from 'components/input/TextInput'
import CheckBoxInput from 'components/input/CheckBoxInput'

import BannerItem1 from 'assets/images/contact-us/contact-us-item-1.png'
import LocationIcon from 'assets/icons/LocationIcon'
import EmailIcon from 'assets/icons/EmailIcon'
import TelephoneIcon from 'assets/icons/TelephoneIcon'
import SelectInput from 'components/input/SelectInput'
import NumberInput from 'components/input/NumberInput'
import Newsletter from 'components/newsletter/Newsletter'

import { countryPhoneCodes } from 'utils/constants/index'
import { SEND_CONTACT_FORM } from 'api';

function ContactUs() {

  const innerWidth = window.innerWidth
  const { fetch: sendContactForm } = useFetch(SEND_CONTACT_FORM)
  const formik = useFormik({
    initialValues: {
      email_address: '',
      full_name: '',
      phone_number: '',
      message: '',
      country_phone_code: '',
      is_privacy_policy_checked: false,
      is_captcha_checked: false,
    },
    validationSchema: Yup.object().shape({
      email_address: Yup.string().required('Please input your email address').email('Invalid email format'),
      full_name: Yup.string().required('Please input your full name'),
      phone_number: Yup.string().required('Please input your phone number'),
      message: Yup.string().required('Please input your message'),
      is_privacy_policy_checked: Yup.boolean(true).required('Please fill this requirement'),
      is_captcha_checked: Yup.boolean(true).required('Please fill this requirement')
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      const newContactFormSubmitted = await sendContactForm({
        data: {
          full_name: values.full_name,
          email_address: values.email_address,
          phone_number: `${values.country_phone_code}${values.phone_number}`,
          message: values.message
        }
      })
      if (newContactFormSubmitted.success) {
        window.location.reload()
      }
    },
  });

  const storedLanguage = localStorage.getItem("language")
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  useEffect(() => {
    if (storedLanguage === 'en') {
      setSelectedLanguage('en')
    }
    if (storedLanguage === 'id') {
      setSelectedLanguage('id')
    }
  }, [storedLanguage]);

  const onChangeCaptcha = (value) => {
    if (value) {
      formik.setValues((values) => ({
        ...values,
        is_captcha_checked: true
      }), false)
    } else {
      formik.setValues((values) => ({
        ...values,
        is_captcha_checked: false
      }), false)
    }
  };

  return (
    <section className='flex-1'>

      <section className='grid grid-cols-1 sm:grid-cols-2'>
        <div className='py-8 sm:py-4 px-10 sm:px-10 xl:px-14 2xl:px-32 flex items-center justify-end'>
          <div className='max-w-2xl space-y-4 sm:space-y-4 xl:space-y-8'>
            <p className='text-2xl sm:text-2xl lg:text-4xl text-indigo-600 font-bold app-animation-translate-x-start-left'>
              {selectedLanguage == 'en' && 'Get in touch with us'}
              {selectedLanguage == 'id' && 'Hubungi kami'}
            </p>
            <p className="text-base sm:text-sm lg:text-lg font-semibold text-indigo-600 app-animation-translate-x-start-left">
              {selectedLanguage == 'en' && 'Do you need help? Or want to know more information? Do not hesitate to contact us.'}
              {selectedLanguage == 'id' && 'Anda butuh bantuan? Atau ingin mengetahui info lebih banyak? Jangan ragu untuk hubungi kami.'}
            </p>
          </div>
        </div>
        <div className=''>
          <a href="#">
            <img
              src={BannerItem1}
              alt="item-1"
              className='w-full object-cover max-h-[50vh] app-animation-translate-x-start-left'
            />
          </a>
        </div>
      </section>

      <section className='grid grid-cols-1 sm:grid-cols-2'>
        <div className='space-y-8 sm:space-y-12'>
          <div className='py-6 sm:py-4 px-10 sm:px-10 xl:px-16 2xl:px-32 flex flex-col justify-center space-y-4 sm:space-y-6'>
            <div className='flex gap-2 sm:gap-4 items-center app-animation-translate-y-start'>
              <div>
                <LocationIcon className='h-6 w-6 sm:h-8 sm:w-8' />
              </div>
              <div className='space-y-1'>
                <p className='text-sm md:text-base 2xl:text-lg font-bold'>
                  Jl. H. R. Rasuna Said Blok X-5 No.Kav. 2-3, RT.1/RW.2, Kuningan, Kuningan Tim., Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12950
                </p>
              </div>
            </div>

            <div className='flex gap-2 sm:gap-4 items-center  app-animation-translate-y-start'>
              <div>
                <TelephoneIcon className='ml-1 h-5 w-5 sm:h-7 sm:w-7 fill-white' />
              </div>
              <div>
                <p className='text-sm md:text-base 2xl:text-lg font-bold'>
                  +6221 5274484
                </p>
              </div>
            </div>

            <div className='flex gap-2 sm:gap-4 items-center  app-animation-translate-y-start'>
              <div>
                <EmailIcon className='ml-1 h-5 w-5 sm:h-7 sm:w-7 fill-white' />
              </div>
              <div>
                <p className='text-sm md:text-base 2xl:text-lg font-bold'>
                  contact@esgtaskforcekadin.id
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col py-6 sm:py-4 px-10 sm:px-8 xl:px-16 2xl:px-32 justify-center max-w-5xl space-y-3 sm:space-y-4 app-animation-translate-y-start'>
          <TextInput
            name="full_name"
            label={selectedLanguage == 'en' ? "Full name" : "Nama Lengkap"}
            placeholder={selectedLanguage == 'en' ? "Fill in your full name" : "Isi nama lengkap anda"}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            error={formik.errors.full_name}
          />
          <TextInput
            name="email_address"
            label={selectedLanguage == 'en' ? "Email Address" : "Alamat Email"}
            placeholder={selectedLanguage == 'en' ? "Fill in your email address" : "Isi alamat email anda"}
            value={formik.values.email_address}
            onChange={formik.handleChange}
            error={formik.errors.email_address}
          />
          <div className='space-y-2'>
            <label for="phone_number" className='font-bold flex text-sm sm:text-base'>
              <p>
                {selectedLanguage == 'en' && 'Phone number'}
                {selectedLanguage == 'id' && 'Nomor Handphone'}
              </p>
            </label>

            <div className='grid grid-cols-3 gap-4 items-center'>
              <SelectInput
                name="country_phone_code"
                placeholder={selectedLanguage == 'en' ? "Choose country" : "Pilih negara"}
                options={countryPhoneCodes.map(code => ({ id: code.code, text: `${code.name}(${code.code})` }))}
                value={formik.values.country_phone_code}
                onChange={formik.handleChange}
              />
              <NumberInput
                className="col-span-2"
                name="phone_number"
                placeholder={selectedLanguage == 'en' ? "Fill in your phone number" : "Isi nomor handphone anda"}
                value={formik.values.phone_number}
                onChange={formik.handleChange}
              />
            </div>

            {formik.errors.phone_number && (
              <p className='text-xs text-app-brown'>{formik.errors.phone_number}</p>
            )}
          </div>

          <TextAreaInput
            name="message"
            label={selectedLanguage == 'en' ? "Message" : "Pesan"}
            rows={8}
            placeholder={selectedLanguage == 'en' ? "Fill in your message" : "Isi pesan yang ingin anda sampaikan"}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.errors.message}
          />

          <CheckBoxInput
            name="is_privacy_policy_checked"
            label={(
              <p className='px-2 text-gray-400'>
                {
                  selectedLanguage == 'en' && (
                    <>
                      BY SUBMITTING THIS CONTACT FORM, YOU CONFIRM YOU HAVE READ AND UNDERSTOOD OUR <a href="privacy-statement" className='text-app-blue'><u>PRIVACY POLICY</u></a>
                    </>
                  )
                }
                {
                  selectedLanguage == 'id' && (
                    <>
                      DENGAN MENGIRIMKAN FORMULIR KONTAK INI, ANDA MENYATAKAN ANDA TELAH MEMBACA DAN MEMAHAMI <a href="privacy-statement" className='text-app-blue'><u>KEBIJAKAN PRIVASI KAMI</u></a>
                    </>
                  )
                }
              </p>
            )}
            value={formik.values.is_privacy_policy_checked}
            onChange={formik.handleChange}
          />

          <div style={{ transform: innerWidth < 768 ? "scale(0.8)" : "scale(1)", transformOrigin: "0 0" }}>
            <ReCAPTCHA
              sitekey="6Le-tx8pAAAAAMWSrMwc7FYsyUm8s5bvs9LQvcS4"
              onChange={onChangeCaptcha}
            />
          </div>

          <button
            onClick={formik.handleSubmit}
            className='text-sm sm:text-base bg-black disabled:opacity-25 text-white w-full rounded-xl p-2 sm:p-3'
            disabled={
              !formik.values.email_address ||
              formik.values.email_address === '' ||
              !formik.values.full_name ||
              formik.values.full_name === '' ||
              !formik.values.phone_number ||
              formik.values.phone_number === '' ||
              !formik.values.message ||
              formik.values.message === '' ||
              !formik.values.is_privacy_policy_checked ||
              !formik.values.is_captcha_checked
            }
          >
            {selectedLanguage == 'en' && 'Send message'}
            {selectedLanguage == 'id' && 'Kirim pesan'}
          </button>
        </div>
      </section>

      <Newsletter />
    </section>
  )
}

export default ContactUs
