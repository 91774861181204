import React from 'react'

function TelephoneIcon({
    className = "h-7 w-7 fill-white"
}) {
    return (
        <svg className={className} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.21885 17.6932C13.1979 22.6722 17.8117 23.2173 19.1662 23.2678C20.803 23.3274 22.4734 21.991 23.196 20.6223C22.0435 19.2704 20.5427 18.2215 18.8981 17.0846C17.9295 18.0532 16.7356 19.8531 15.1428 19.2082C14.2377 18.8444 12.0026 17.8149 10.0499 15.8609C8.09584 13.9081 7.06766 11.6731 6.7012 10.7692C6.05632 9.17514 7.86145 7.97733 8.83136 7.00743C7.69441 5.33697 6.66365 3.7973 5.31433 2.70309C3.92617 3.42825 2.58203 5.08576 2.64289 6.74456C2.6934 8.09905 3.23856 12.7129 8.21885 17.6932ZM19.0704 25.8551C17.2057 25.7865 11.9211 25.0574 6.38653 19.5229C0.853303 13.9897 0.124258 8.70638 0.0543319 6.84038C-0.0492623 3.99672 2.12881 1.23464 4.64485 0.155966C4.94784 0.0251358 5.27963 -0.0246754 5.60767 0.0114207C5.93571 0.0475168 6.24874 0.16828 6.51602 0.36186C8.59827 1.88081 10.0343 4.1819 11.2684 5.98444C11.5251 6.35926 11.6427 6.81203 11.6009 7.2644C11.559 7.71677 11.3603 8.14028 11.0392 8.46163L9.28329 10.2189C9.69119 11.1188 10.5212 12.6702 11.8809 14.0298C13.2406 15.3895 14.7919 16.2195 15.6932 16.6274L17.4478 14.8715C17.7704 14.5497 18.1957 14.3513 18.6495 14.3108C19.1034 14.2704 19.557 14.3904 19.9315 14.6501C21.7703 15.9243 23.9302 17.3397 25.5048 19.3559C25.7141 19.6251 25.8473 19.9456 25.8903 20.2839C25.9334 20.6221 25.8848 20.9658 25.7496 21.2788C24.6657 23.8078 21.9231 25.96 19.0704 25.8551Z" fill="black" />
        </svg>
    )
}

export default TelephoneIcon