import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import logo from 'assets/images/kadin-logo-web.png'
import TwitterIcon from 'assets/icons/social-media/TwitterIcon'
import FacebookIcon from 'assets/icons/social-media/FacebookIcon'
import InstagramIcon from 'assets/icons/social-media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social-media/LinkedinIcon'

function Footer({
    isEnglish,
    setIsEnglish,
    handleSwitchLanguage
}) {
    const facebookLink = 'https://www.facebook.com'
    const twitterLink = 'https://www.twitter.com'
    const linkedinLink = 'https://www.linkedin.com'
    const instagramLink = 'https://www.instagram.com'

    return (
        <div className='bg-white text-black'>
            <div className='app-nav-container pb-6 flex flex-col sm:flex-row justify-between items-center sm:justify-between sm:items-center gap-4 sm:gap-4'>
                <div className='flex flex-col items-center md:items-start md:flex-row pt-2 sm:pt-0 text-[10px] sm:text-xs md:text-sm'>
                    <p>
                        KADIN ESG Initiatives © {moment().year()} - All Rights Reserved.
                    </p>
                    <a href='/privacy-statement' className='mt-2 md:ml-2 md:mt-0'>
                        Privacy Policy.
                    </a>
                    <p className='mt-2 md:ml-2 md:mt-0'>
                        Powered by <u><a href='https://sustainability.co.id/' target="_blank" rel="noopener noreferrer">ISA</a></u> & <u><a href='https://cloudxier.com/' target="_blank" rel="noopener noreferrer">Cloudxier</a></u>
                    </p>
                    <label className="mt-2 sm:mt-2 md:ms-3 md:mt-0  relative inline-flex items-center cursor-pointer">
                        <span className="me-3 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-300">ID</span>
                        <input
                            type="checkbox"
                            value={isEnglish}
                            checked={isEnglish}
                            onChange={() => handleSwitchLanguage()}
                            className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[26px] sm:after:start-[26px] md:after:start-[28px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ms-3 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-300">EN</span>
                    </label>
                </div>
                <div className='flex gap-4'>
                    <a href={instagramLink && instagramLink !== '' ? instagramLink : '/'} target="_blank" rel="noopener noreferrer">
                        <InstagramIcon className="fill-white w-8 h-8 sm:h-8  sm:w-9 md:h-9  md:w-10" />
                    </a>
                    <a href={facebookLink && facebookLink !== '' ? facebookLink : '/'} target="_blank" rel="noopener noreferrer">
                        <FacebookIcon className="fill-white w-8 h-8 sm:h-8  sm:w-9 md:h-9  md:w-10" />
                    </a>
                    <a href={twitterLink && twitterLink !== '' ? twitterLink : '/'} target="_blank" rel="noopener noreferrer">
                        <TwitterIcon className="fill-white w-8 h-8 sm:h-8  sm:w-9 md:h-9  md:w-10" />
                    </a>
                    <a href={linkedinLink && linkedinLink !== '' ? linkedinLink : '/'} target="_blank" rel="noopener noreferrer">
                        <LinkedinIcon className="fill-white w-8 h-8 sm:h-8  sm:w-9 md:h-9  md:w-10" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer