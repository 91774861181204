import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowIcon'
import React from 'react'

function SelectInput({
    name,
    className,
    classNameInput,
    label,
    options,
    disabled,
    onChange,
    onBlur,
    error,
    value,
    placeholder = "Choose one"
}) {
    return (
        <div className={`flex flex-col space-y-2 ${className}`}>
            {label && (
                <label htmlFor={name} className='font-bold flex text-sm md:text-base'>{label}</label>
            )}
            <div className='relative flex items-center overflow'>
                <select
                    id={name}
                    name={name}
                    className={`${classNameInput} text-sm md:text-base truncate appearance-none w-full border border-indigo-600 rounded-lg bg-transparent px-3 py-2 pr-6 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                >
                    {[{ id: 0, text: placeholder }, ...options]?.map((option, i) => (
                        <option selected={option?.id?.toString() === value?.toString() || option.text === value} key={i} value={option.id}>{option.text}</option>
                    ))}
                </select>
                <KeyboardArrowDownIcon
                    className="absolute right-0 mr-3 fill-black h-3 w-3"
                />
            </div>
            {error && (
                <p className='text-xs text-app-brown'>{error}</p>
            )}
        </div>
    )
}

export default SelectInput