import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import DownloadConfirmation from 'components/modal/DownloadConfirmation';
import Banner1 from 'assets/images/landing-page/banner-1.png'
import Banner2 from 'assets/images/landing-page/banner-2.png'
import Banner4 from 'assets/images/landing-page/banner-4.jpg'
import Banner5 from 'assets/images/landing-page/banner-5.jpg'
import Banner6 from 'assets/images/landing-page/banner-6.jpg'
import Banner7 from 'assets/images/landing-page/banner-7.jpg'
import PodcastItem1 from 'assets/images/landing-page/podcasts/podcast-item-1.png'
import PodcastItem2 from 'assets/images/landing-page/podcasts/podcast-item-2.jpg'
import PodcastItem3 from 'assets/images/landing-page/podcasts/podcast-item-3.jpg'
import PodcastItem4 from 'assets/images/landing-page/podcasts/podcast-item-4.jpg'
import PodcastItem5 from 'assets/images/landing-page/podcasts/podcast-item-5.jpg'
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import Newsletter from 'components/newsletter/Newsletter';

function Home() {
  const innerWidth = window.innerWidth
  const [isOpenDownloadConfirmation, setOpenDownloadConfirmation] = useState({ show: false, case: '', title: '' });
  const storedLanguage = localStorage.getItem("language")
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  useEffect(() => {
    if (storedLanguage === 'en') {
      setSelectedLanguage('en')
    }
    if (storedLanguage === 'id') {
      setSelectedLanguage('id')
    }
  }, [storedLanguage]);

  const podcasts = [
    {
      id: 1,
      name: 'PODCAST SERIES',
      href: '#',
      title: 'a Holistic approach of ESG value creation journey',
    },
    {
      id: 2,
      name: 'PODCAST SERIES',
      href: '#',
      title: 'a Holistic approach of ESG value creation journey',
    },
    {
      id: 3,
      name: 'PODCAST SERIES',
      href: '#',
      title: 'a Holistic approach of ESG value creation journey',
    },
    {
      id: 4,
      name: 'PODCAST SERIES',
      href: '#',
      title: 'a Holistic approach of ESG value creation journey',
    },
    {
      id: 5,
      name: 'PODCAST SERIES',
      href: '#',
      title: 'a Holistic approach of ESG value creation journey',
    },
    {
      id: 6,
      name: 'PODCAST SERIES',
      href: '#',
      title: 'a Holistic approach of ESG value creation journey',
    },
  ]



  return (
    <div className='flex-1'>
      {isOpenDownloadConfirmation.show && (
        <DownloadConfirmation
          setOpenDownloadConfirmation={setOpenDownloadConfirmation}
          caseName={isOpenDownloadConfirmation.case}
          title={isOpenDownloadConfirmation.title}
        />
      )}

      <section>
        <div className="relative isolate overflow-hidden py-16 lg:py-20 xl:py-24">
          <img
            src={Banner1}
            alt="main-banner"
            className="absolute inset-0 -z-10 h-full w-full brightness-75 object-cover object-center md:object-center app-animation-opacity-start"
          />
          <div className="mx-auto px-8 sm:px-16 md:pr-40 2xl:pr-80">
            <div className='bg-black/50 absolute w-100 h-100 left-0 right-0 top-0 bottom-0'></div>
            <div className="mx-auto sm:max-w-2xl lg:max-w-5xl space-y-4 sm:space-y-6 app-animation-opacity-start">
              <p className="text-sm lg:text-3xl xl:text-4xl leading-relaxed xl:leading-relaxed font-bold text-white">
                {selectedLanguage === 'en' && (
                  <>
                    Welcome to the ESG Task Force page! <br />
                    Here, we provide comprehensive information on Environmental, Social, and Governance (ESG),
                    encompassing clear insights and effective implementation strategies.
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Selamat datang di halaman utama ESG Task Force! <br />
                    Di sini, kami menyajikan informasi komprehensif mengenai Lingkungan, Sosial, dan Tata Kelola (ESG),
                    mencakup wawasan yang terperinci dan strategi implementasi yang efisien.
                  </>
                )}
              </p>
              <p className="text-xs lg:text-base xl:text-lg text-white">
                {selectedLanguage === 'en' && (
                  <>
                    Learn more about guides, infographics and case studies of companies in Indonesia that have successfully integrated ESG principles. Join us in an exploration to gain a deeper understanding of sustainability and responsible corporate governance.
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Pelajari lebih lanjut panduan, infografis, dan studi kasus perusahaan di Indonesia yang berhasil mengintegrasikan prinsip-prinsip ESG. Bergabunglah dengan kami dalam eksplorasi untuk memperoleh pemahaman yang lebih mendalam tentang keberlanjutan dan tata kelola perusahaan yang bertanggung jawab.
                  </>
                )}
              </p>
              {/* <button
                                onClick={console.log("Learn more.")}
                                className='text-xs lg:text-base px-3 py-2 lg:px-6 lg:py-4 bg-indigo-900 text-white rounded-full'
                            >
                                Learn More
                            </button> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='flex flex-col text-center py-8 lg:py-12 px-4 space-y-4 lg:space-y-8'>
          <p className='text-base  lg:text-4xl font-semibold text-indigo-600 app-animation-translate-y-start'>
            {selectedLanguage === 'en' && (
              <>
                Introduction to ESG
              </>
            )}
            {selectedLanguage === 'id' && (
              <>
                Pengantar tentang ESG
              </>
            )}
          </p>
          <p className='mx-10 md:mx-20 text-xs lg:text-base font-medium app-animation-translate-y-start'>
            {selectedLanguage === 'en' && (
              <>
                ESG represents the Company's disclosure of Non-Financial Performance in reports and other communication channels. Defined as a business model, ESG Strategy seamlessly incorporates the management of Environmental, Social, and Governance risks and opportunities into company operations.
                <br /> <br />
                Exploring ESG and implementing its strategies is crucial for promoting sustainable growth and embedding responsible business practices.
              </>
            )}
            {selectedLanguage === 'id' && (
              <>
                ESG mencakup Pengungkapan Kinerja Non-Finansial Perusahaan melalui laporan dan media komunikasi perusahaan, sementara Strategi ESG merupakan model bisnis yang mengintegrasikan pengelolaan risiko dan peluang di bidang Lingkungan, Sosial, dan Tata Kelola ke dalam operasional perusahaan.
                <br /> <br />
                Mempelajari ESG dan menerapkan strateginya menjadi kunci dalam pertumbuhan berkelanjutan dan praktik bisnis yang bertanggung jawab.
              </>
            )}
          </p>
        </div>
      </section>

      <section className='grid grid-cols-1 sm:grid-cols-2'>
        <div className='max-h-56 sm:max-h-max xl:max-h-[26rem] 2xl:max-h-[30rem] order-1 sm:order-none' >
          <a href="/" target='_blank' rel="noopener noreferrer">
            <img
              src={Banner4}
              alt="item-1"
              className='w-full h-full object-center  app-animation-translate-x-start-right'
            />
          </a>
        </div>
        <div className='order-2 sm:order-none py-8 px-10 sm:px-10 lg:px-14 xl:px-16 flex items-center'>
          <div className='max-w-2xl space-y-4 sm:space-y-4 lg:space-y-6'>
            <p className='text-base sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl font-medium app-animation-translate-x-start-right'>
              {selectedLanguage === 'en' && (
                <>
                  KADIN ESG Guide
                </>
              )}
              {selectedLanguage === 'id' && (
                <>
                  Panduan ESG KADIN
                </>
              )}
            </p>
            <p className='text-xs sm:text-xs lg:text-base app-animation-translate-x-start-right'>
              {selectedLanguage === 'en' && (
                <>
                  This guide covers ESG's history, urgency, and key aspects like its concept, related rating agencies, and its connection to CSR, ESG, SDGs, and sustainability in Indonesia. It also includes practical steps and case studies for implementing ESG.
                  <br /> <br />
                  This guide is thoughtfully crafted to offer readers a comprehensive understanding of ESG and how to implement it effectively.
                </>
              )}
              {selectedLanguage === 'id' && (
                <>
                  Panduan ini mencakup sejarah, urgensi, dan aspek-aspek utama ESG seperti konsepnya, lembaga pemeringkat terkait, dan hubungannya dengan CSR, ESG, SDGs, dan keberlanjutan di Indonesia. Panduan ini juga mencakup langkah-langkah praktis dan studi kasus untuk penerapan ESG.
                  <br /> <br />
                  Panduan ini dipersiapkan dengan cermat untuk memberikan pembaca pengetahuan yang komprehensif tentang ESG dan implementasinya.
                </>
              )}
            </p>
            <button
              onClick={() => setOpenDownloadConfirmation({ show: true, case: 'guide', title: selectedLanguage === 'en' ? "KADIN ESG Guide" : selectedLanguage === 'id' ? "Panduan ESG KADIN" : "KADIN ESG Guide" })}
              className='text-xs sm:text-xs lg:text-base px-3 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-3 bg-indigo-900 text-white rounded-full app-animation-translate-x-start-right'
            >
              {selectedLanguage === 'en' && (
                <>
                  Download Here
                </>
              )}
              {selectedLanguage === 'id' && (
                <>
                  Unduh Di Sini
                </>
              )}
            </button>
          </div>
        </div>
      </section>

      {innerWidth < 640 ? (
        <section className='grid grid-cols-1 sm:grid-cols-2'>
          <div className='max-h-max order-1 sm:order-none'>
            <a href="/" target='_blank' rel="noopener noreferrer">
              <img
                src={Banner5}
                alt="item-1"
                className='w-full h-full object-center app-animation-translate-x-start-left'
              />
            </a>
          </div>
          <div className='order-2 sm:order-none py-8 sm:py-12 px-10 sm:px-10 lg:px-14 xl:px-16 flex items-center justify-end'>
            <div className='max-w-2xl space-y-4 sm:space-y-4 lg:space-y-6'>
              <p className='text-base sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl font-medium app-animation-translate-x-start-left'>
                {selectedLanguage === 'en' && (
                  <>
                    KADIN ESG Guide Infographic
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Infografis Panduan ESG KADIN
                  </>
                )}
              </p>
              <p className='text-xs sm:text-xs lg:text-base app-animation-translate-x-start-left'>
                {selectedLanguage === 'en' && (
                  <>
                    This infographic serves as a concise and interactive rendition of our guidebook. It provides an overview of ESG, explores ESG materiality, offers insights into the ESG ecosystem, outlines initial steps to embark on the ESG journey, and presents a comprehensive guide to understanding and adhering to IFC Standards.
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Infografis ini merupakan versi singkat dan interaktif dari buku panduan kami. Infografis ini memberikan gambaran menyeluruh tentang ESG, mengeksplorasi materialitas ESG, memberikan wawasan mengenai ekosistem ESG, merinci langkah-langkah awal untuk memulai perjalanan ESG, dan menyajikan panduan yang komprehensif untuk memahami serta mematuhi Standar IFC.
                  </>
                )}
              </p>
              <button
                onClick={() => setOpenDownloadConfirmation({ show: true, case: 'guideinfographic', title: selectedLanguage === 'en' ? "KADIN ESG Guide Infographic" : selectedLanguage === 'id' ? "Infografis Panduan ESG KADIN" : "KADIN ESG Guide Infographic" })}
                className='text-xs sm:text-xs lg:text-base px-3 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-3 bg-indigo-900 text-white rounded-full app-animation-translate-x-start-left'
              >
                {selectedLanguage === 'en' && (
                  <>
                    Download Here
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Unduh Di Sini
                  </>
                )}
              </button>
            </div>
          </div>
        </section>
      ) : (
        <section className='grid grid-cols-1 sm:grid-cols-2'>
          <div className='py-8 px-10 sm:px-10 sm:py-12 lg:px-14 xl:px-16 flex items-center justify-end'>
            <div className='max-w-2xl space-y-4 sm:space-y-4 lg:space-y-6'>
              <p className='text-base sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl font-medium app-animation-translate-x-start-left'>
                {selectedLanguage === 'en' && (
                  <>
                    KADIN ESG Guide Infographic
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Infografis Panduan ESG KADIN
                  </>
                )}
              </p>
              <p className='text-xs sm:text-xs lg:text-base app-animation-translate-x-start-left'>
                {selectedLanguage === 'en' && (
                  <>
                    This infographic serves as a concise and interactive rendition of our guidebook. It provides an overview of ESG, explores ESG materiality, offers insights into the ESG ecosystem, outlines initial steps to embark on the ESG journey, and presents a comprehensive guide to understanding and adhering to IFC Standards.
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Infografis ini merupakan versi singkat dan interaktif dari buku panduan kami. Infografis ini memberikan gambaran menyeluruh tentang ESG, mengeksplorasi materialitas ESG, memberikan wawasan mengenai ekosistem ESG, merinci langkah-langkah awal untuk memulai perjalanan ESG, dan menyajikan panduan yang komprehensif untuk memahami serta mematuhi Standar IFC.
                  </>
                )}
              </p>
              <button
                onClick={() => setOpenDownloadConfirmation({ show: true, case: 'guideinfographic', title: selectedLanguage === 'en' ? "KADIN ESG Guide Infographic" : selectedLanguage === 'id' ? "Infografis Panduan ESG KADIN" : "KADIN ESG Guide Infographic" })}
                className='text-xs sm:text-xs lg:text-base px-3 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-3 bg-indigo-900 text-white rounded-full app-animation-translate-x-start-left'
              >
                {selectedLanguage === 'en' && (
                  <>
                    Download Here
                  </>
                )}
                {selectedLanguage === 'id' && (
                  <>
                    Unduh Di Sini
                  </>
                )}
              </button>
            </div>
          </div>
          <div className='max-h-max'>
            <a href="/" target='_blank' rel="noopener noreferrer">
              <img
                src={Banner5}
                alt="item-1"
                className='w-full h-full object-center app-animation-translate-x-start-left'
              />
            </a>
          </div>
        </section>
      )}


      {/* <section className='grid grid-cols-1 sm:grid-cols-2'>
                <div className='order-1 sm:order-none' >
                    <a href="/" target='_blank' rel="noopener noreferrer">
                        <img
                            src={Banner6}
                            alt="item-1"
                            className='w-full h-full object-center max-h-56 sm:max-h-64 xl:max-h-96 2xl:max-h-[30rem] app-animation-translate-x-start-right'
                        />
                    </a>
                </div>
                <div className='order-2 sm:order-none py-8 px-10 sm:px-10 lg:px-14 xl:px-16 flex items-center'>
                    <div className='max-w-2xl space-y-4 sm:space-y-4 lg:space-y-6'>
                        <p className='text-base sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl font-medium app-animation-translate-x-start-right'>
                            ESG Implementation in Financial Technology (Fintech) Start-up
                        </p>
                        <p className='text-xs sm:text-xs lg:text-base app-animation-translate-x-start-right'>
                            Lorem ipsum dolor sit amet, lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.
                        </p>
                        <button
                            onClick={() => setOpenDownloadConfirmation(true)}
                            className='text-xs sm:text-xs lg:text-base px-3 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-3 bg-indigo-900 text-white rounded-full app-animation-translate-x-start-right'
                        >
                            Download Here
                        </button>
                    </div>
                </div>
            </section> */}

      {/* {innerWidth < 640 ? (
                <section className='grid grid-cols-1 sm:grid-cols-2'>
                    <div className='order-1 sm:order-none'>
                        <a href="/" target='_blank' rel="noopener noreferrer">
                            <img
                                src={Banner7}
                                alt="item-1"
                                className='w-full h-full object-center max-h-56 sm:max-h-64 xl:max-h-96 2xl:max-h-[30rem] app-animation-translate-x-start-left'
                            />
                        </a>
                    </div>
                    <div className='order-2 sm:order-none py-8 sm:py-12 px-10 sm:px-10 lg:px-14 xl:px-16 flex items-center justify-end'>
                        <div className='max-w-2xl space-y-4 sm:space-y-4 lg:space-y-6'>
                            <p className='text-base sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl font-medium app-animation-translate-x-start-left'>
                                ESG Implementation in Financial Technology (Fintech) Start-up
                            </p>
                            <p className='text-xs sm:text-xs lg:text-base app-animation-translate-x-start-left'>
                                Lorem ipsum dolor sit amet, lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.
                            </p>
                            <button
                                onClick={() => setOpenDownloadConfirmation(true)}
                                className='text-xs sm:text-xs lg:text-base px-3 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-3 bg-indigo-900 text-white rounded-full app-animation-translate-x-start-left'
                            >
                                Download Here
                            </button>
                        </div>
                    </div>
                </section>
            ) : (
                <section className='grid grid-cols-1 sm:grid-cols-2'>
                    <div className='py-8 px-10 sm:px-10 lg:px-14 xl:px-16 flex items-center justify-end'>
                        <div className='max-w-2xl space-y-4 sm:space-y-4 lg:space-y-6'>
                            <p className='text-base sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl font-medium app-animation-translate-x-start-left'>
                                ESG Implementation in Financial Technology (Fintech) Start-up
                            </p>
                            <p className='text-xs sm:text-xs lg:text-base app-animation-translate-x-start-left'>
                                Lorem ipsum dolor sit amet, lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.
                            </p>
                            <button
                                onClick={() => setOpenDownloadConfirmation(true)}
                                className='text-xs sm:text-xs lg:text-base px-3 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-3 bg-indigo-900 text-white rounded-full app-animation-translate-x-start-left'
                            >
                                Download Here
                            </button>
                        </div>
                    </div>
                    <div className=''>
                        <a href="/" target='_blank' rel="noopener noreferrer">
                            <img
                                src={Banner7}
                                alt="item-1"
                                className='w-full h-full object-center max-h-56 sm:max-h-64 xl:max-h-96 2xl:max-h-[30rem] app-animation-translate-x-start-left'
                            />
                        </a>
                    </div>
                </section>
            )} */}

      <section>
        <div className="relative isolate overflow-hidden py-28 sm:py-32 lg:py-36 xl:py-52 2xl:py-56">
          <img
            src={Banner2}
            alt="main-banner"
            className="absolute inset-0 -z-10 brightness-75 h-full w-full object-cover object-center md:object-center app-animation-opacity-start"
          />
          <div className="mx-auto sm:max-w-lg lg:max-w-xl xl:max-w-4xl px-6 lg:px-8">
            <div className="mx-auto sm:max-w-lg lg:max-w-xl xl:max-w-4xl lg:mx-0 ">
              <p className="text-xl sm:text-3xl lg:text-4xl xl:text-6xl text-center leading-relaxed font-medium text-white sm:text-12xl sm:leading-relaxed app-animation-opacity-start">
                {selectedLanguage == 'en' && 'For the long-term business success and growth'}
                {selectedLanguage == 'id' && 'Untuk kesuksesan dan keberlanjutan bisnis'}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-22 lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl lg:px-8">
          <h2 className="text-lg lg:text-2xl xl:text-4xl xl:py-4 text-center font-bold text-indigo-600 app-animation-translate-y-start">
            {selectedLanguage === 'en' && (
              <>
                Explore Latest Podcasts and Videos
              </>
            )}
            {selectedLanguage === 'id' && (
              <>
                Telusuri Podcast dan Video Terbaru Kami
              </>
            )}
          </h2>

          <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
            <iframe src="https://podcasters.spotify.com/pod/show/kadin-esg-task-force/embed/episodes/Bagaimana-Implementasi-ESG-secara-Holistik--Studi-kasus-Vale-Indonesia-Maria-R--N--Radyati--PhD-e2ch7s5/a-aaltj2m" width="100%" frameborder="0" scrolling="no"></iframe>
            <iframe src="https://podcasters.spotify.com/pod/show/kadin-esg-task-force/embed/episodes/Perbedaan--Keterkaitan-CSR--ESG--dan-Sustainability-W-Maria-R-N--Radyati--PhD-e2ch7lg/a-aaltie0" width="100%" frameborder="0" scrolling="no"></iframe>
            <iframe src="https://podcasters.spotify.com/pod/show/kadin-esg-task-force/embed/episodes/Implementasi-ESG-pada-PT-Amartha-Mikro-Fintek-W-Aria-Widyanto-e2ch7di/a-aalthlu" width="100%" frameborder="0" scrolling="no"></iframe>
            <iframe src="https://podcasters.spotify.com/pod/show/kadin-esg-task-force/embed/episodes/Implementasi-ESG-pada-Saratoga-Investama-Sedaya-W-Catharina-Latjuba-e2ch6p3/a-aaltfpi" width="100%" frameborder="0" scrolling="no"></iframe>
            {/* {podcasts.map((podcast) => (
              <div key={podcast.id} className="group relative">
                <div className="w-full h-52 xl:h-96 overflow-hidden bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-100">
                  <img
                    src={
                      podcast.id == 1 ? PodcastItem1
                        : podcast.id == 2 ? PodcastItem2
                          : podcast.id == 3 ? PodcastItem3
                            : podcast.id == 4 ? PodcastItem4
                              : podcast.id == 5 ? PodcastItem5
                                : PodcastItem1
                    }
                    alt="podcast-item"
                    className="w-full h-52 xl:h-96 brightness-75 object-cover object-center lg:h-full lg:w-full app-animation-scale-start"
                  />
                </div>
                <div className="w-full absolute bottom-0 px-8 py-6 xl:py-8 space-y-2 xl:space-y-8">
                  <div>
                    <h3 className="text-xs xl:text-base text-white app-animation-translate-y-start">
                      <a href={podcast.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {podcast.name}
                      </a>
                    </h3>
                    <p className="py-2 xl:py-5 text-lg xl:text-3xl font-bold text-white app-animation-translate-y-start">
                      {selectedLanguage === 'en' && (
                        <>
                          Podcast Title
                        </>
                      )}
                      {selectedLanguage === 'id' && (
                        <>
                          Judul Podcast
                        </>
                      )}
                    </p>
                  </div>
                  <div className='flex flex-col items-end'>
                    <a href={podcast.href}>
                      <div>
                        <ArrowRightIcon className="h-4 w-4 sm:h-5 sm:w-5 fill-white app-animation-translate-y-start" />
                      </div>
                    </a>
                  </div>
                </div>

              </div>
            ))} */}
          </div>
          <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
            <iframe width="100%" height="300px" src="https://www.youtube.com/embed/kBUN18uowLQ?si=8TS_JcPuUhEh6LAn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe width="100%" height="300px" src="https://www.youtube.com/embed/LbEFFYZLq9g?si=SaGlgUq4_KCcj8aX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe width="100%" height="300px" src="https://www.youtube.com/embed/d8VVjglvCr0?si=msbVny6ya_6NnJFz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe width="100%" height="300px" src="https://www.youtube.com/embed/nIfBaED_LaI?si=hZvL2Esr-ecGrDqz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>

      </section>

      <Newsletter />
    </div>
  )
}

export default Home