import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import MemberDetail from 'components/modal/MemberDetails';
import Member1 from 'assets/images/our-members/member-1.png';
import Member2 from 'assets/images/our-members/member-2.png';
import Member3 from 'assets/images/our-members/member-3.png';
import Member4 from 'assets/images/our-members/member-4.png';
import Member5 from 'assets/images/our-members/member-5.png';
import Newsletter from 'components/newsletter/Newsletter';

import useFetch from 'utils/hooks/useFetch';

function OurMember() {
    const [openMemberDetail, setOpenMemberDetail] = useState(false)

    const members = [
        {
            id: 1,
            title: 'Brandon Lee',
            desc: 'President Director',
        },
        {
            id: 2,
            title: 'John Doe',
            desc: 'General Manager',
        },
        {
            id: 3,
            title: 'Brenda Lee',
            desc: 'Vice President Director',
        },
        {
            id: 4,
            title: 'Shirley Jane',
            desc: 'Project Manager',
        },
        {
            id: 5,
            title: 'Jane Doe',
            desc: 'Assistant Director',
        },
        {
            id: 6,
            title: 'Brandon Lee',
            desc: 'President Director',
        },
        {
            id: 7,
            title: 'John Doe',
            desc: 'General Manager',
        },
        {
            id: 8,
            title: 'Brenda Lee',
            desc: 'Vice President Director',
        },
        {
            id: 9,
            title: 'Shirley Jane',
            desc: 'Project Manager',
        },
        {
            id: 10,
            title: 'Jane Doe',
            desc: 'Assistant Director',
        },
        {
            id: 11,
            title: 'Brandon Lee',
            desc: 'President Director',
        },
        {
            id: 12,
            title: 'John Doe',
            desc: 'General Manager',
        },
    ]

    return (
        <div className='flex-1'>
            {openMemberDetail && (
                <MemberDetail
                    setOpenMemberDetail={setOpenMemberDetail}
                />
            )}

            <section>
                <div className="mx-auto max-w-2xl px-4 py-8 lg:px-8 lg:py-16 sm:px-6 sm:py-22 lg:max-w-7xl">
                    <h2 className="text-xl lg:text-3xl xl:text-4xl text-center font-bold text-indigo-600 app-animation-translate-y-start">Our Members</h2>
                    <div className="mt-8 lg:mt-16 px-4 lg:px-8 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-4 lg:gap-y-6 lg:gap-x-8 xl:gap-x-12">
                        {members.map((member) => (
                            <div key={member.id} className="group relative">
                                <div onClick={() => setOpenMemberDetail(true)} className="aspect-h-1 aspect-w-1 h-full w-full max-h-32 sm:max-h-36 lg:max-h-44 xl:max-h-64 cursor-pointer overflow-hidden bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-100">
                                    <img
                                        src={
                                            member.id == 1 ? Member1
                                                : member.id == 2 ? Member2
                                                    : member.id == 3 ? Member3
                                                        : member.id == 4 ? Member4
                                                            : member.id == 5 ? Member5
                                                                : member.id == 6 ? Member1
                                                                    : member.id == 7 ? Member2
                                                                        : member.id == 8 ? Member3
                                                                            : member.id == 9 ? Member4
                                                                                : member.id == 10 ? Member5
                                                                                    : member.id == 11 ? Member1
                                                                                        : member.id == 12 ? Member2
                                                                                            : Member3
                                        }
                                        alt="podcast-item"
                                        className="h-full w-full max-h-32 sm:max-h-36 lg:max-h-44 xl:max-h-64 object-cover object-center app-animation-scale-start"
                                    />
                                </div>
                                <div className="bottom-0 px-2 py-2 lg:py-4 space-y-2">
                                    <div>
                                        <p className="text-sm lg:text-lg xl:text-xl text-black font-bold app-animation-translate-y-start">
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {member.title}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-xs lg:text-sm xl:text-base text-black app-animation-translate-y-start">{member.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default OurMember
