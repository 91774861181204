import React from 'react'

function NotFound() {
    return (
        <div className='flex-1 flex flex-col gap-4 p-4 items-center justify-center h-96'>
            <div className='text-4xl sm:text-5xl font-bold'>404</div>
            <div className='text-sm sm:text-base mb-4'>Page Not Found</div>
            <a className='bg-black text-white px-3 py-2 text-xs sm:text-sm rounded-md' href="/">Back to home</a>
        </div>
    )
}

export default NotFound