import React from 'react'

function FacebookIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.7517 0H5.53596C2.48288 0 0 2.48288 0 5.53596V38.7517C0 41.8048 2.48288 44.2877 5.53596 44.2877H38.7517C41.8048 44.2877 44.2877 41.8048 44.2877 38.7517V5.53596C44.2877 2.48288 41.8048 0 38.7517 0Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0001 19.6238H24.3705V16.752C24.3705 15.9594 25.0209 16.0341 25.8223 16.0341H27.2742V12.4443H24.3705C23.2153 12.4443 22.1075 12.8982 21.2906 13.706C20.4738 14.5139 20.0149 15.6096 20.0149 16.752V19.6238H17.1112V23.2136H20.0149V31.111H24.3705V23.2136H26.5483L28.0001 19.6238Z" fill="white" />
        </svg>

    )
}

export default FacebookIcon