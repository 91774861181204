import React, { useState } from 'react'
import CloseIcon from 'assets/icons/CloseIcon'

function Menu({
  isOpen,
  onClose,
}) {

  return (
    <div className={`md:hidden fixed top-0 bottom-0 left-0 right-0 bg-black/80 backdrop-blur-lg ${isOpen ? "inline-block" : "hidden"} z-50`}>
      <div className='app-nav-container h-full flex flex-col gap-6'>
        <div className='text-right font-medium'>
          <button className='pt-2 text-sm active:border-b-2 active:border-black transition-all' onClick={onClose}>
            <CloseIcon className="h-5 w-5" stroke="white" />
          </button>
        </div>
        <div className={`flex flex-col justify-between flex-1`}>
          <ul className='flex flex-col gap-2 font-medium text-white'>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            {/* <li>
                            <a href="/our-members">Our Members</a>
                        </li> */}
            {/* <li >
              <a href="/articles">Resources and Articles</a>
            </li> */}
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Menu
