import axios from 'axios'
import getServerURL from 'utils/constants/api'

const api = axios.create({
    baseURL: getServerURL(),
    withCredentials: true
})

export const SEND_CONTACT_FORM = {
    method: "post",
    url: "/email/contact-us"
}

export const SEND_DOWNLOAD_NOTICE_TO_ADMIN = {
    method: "post",
    url: "/email/download-case-study/admin"
}

export default api;
