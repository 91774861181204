import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment';
import useFetch from 'utils/hooks/useFetch';

import Newsletter from 'components/newsletter/Newsletter';
import ArticleItemDetail from 'assets/images/blog-page/article-detail.png'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import TwitterIcon from 'assets/icons/social-media/TwitterIcon'
import FacebookIcon from 'assets/icons/social-media/FacebookIcon'
import InstagramIcon from 'assets/icons/social-media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social-media/LinkedinIcon'
import ClipboardIcon from 'assets/icons/ClipboardIcon'

function PrivacyPolicy() {
    const { id } = useParams();

    const facebookLink = 'https://www.facebook.com'
    const twitterLink = 'https://www.twitter.com'
    const linkedinLink = 'https://www.linkedin.com'
    const instagramLink = 'https://www.instagram.com'

    const handleClipboard = () => {
        navigator.clipboard.writeText(window.location.href)
    }

    return (
        <section className='flex-1'>
            <div className='app-main-container py-4 sm:py-6 md:py-8 lg:py-10 xl:py-14 px-10 max-w-[25rem] sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl flex flex-col gap-4 md:gap-4 lg:gap-4 xl:gap-4'>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-2xl lg:text-4xl 2xl:text-5xl font-bold text-indigo-600 app-animation-translate-y-start'>Privacy Policy Statement</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Use of Information</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Protection of Personal Data</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Sharing of Personal Information</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Retention of Personal Data</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Personal Data Protection Rights</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Privacy Policies of Other Websites</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Changes To This Privacy Statement</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>Acknowledgement and Consent</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='py-2 md:py-0 lg:py-0 app-animation-translate-y-start'>
                        <div>
                            <p className='text-xl lg:text-2xl 2xl:text-3xl font-semibold app-animation-translate-y-start'>How To Contact Us</p>
                        </div>
                    </div>
                    <div className='text-xs leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed lg:text-sm 2xl:text-base py-4 md:py-4 lg:py-6 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>If you have any questions regarding this Privacy Statement or you would like to obtain access to your Personal Information, please contact <u>esg-inquiries@kadin.id</u></p>
                    </div>
                </section>

            </div>
            <Newsletter />
        </section >
    )
}

export default PrivacyPolicy
