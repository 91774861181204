import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment';
import useFetch from 'utils/hooks/useFetch';

import Newsletter from 'components/newsletter/Newsletter';
import ArticleItemDetail from 'assets/images/blog-page/article-detail.png'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import TwitterIcon from 'assets/icons/social-media/TwitterIcon'
import FacebookIcon from 'assets/icons/social-media/FacebookIcon'
import InstagramIcon from 'assets/icons/social-media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social-media/LinkedinIcon'
import ClipboardIcon from 'assets/icons/ClipboardIcon'

function ArticlesDetails() {
    const { id } = useParams();

    const facebookLink = 'https://www.facebook.com'
    const twitterLink = 'https://www.twitter.com'
    const linkedinLink = 'https://www.linkedin.com'
    const instagramLink = 'https://www.instagram.com'

    const handleClipboard = () => {
        navigator.clipboard.writeText(window.location.href)
    }

    return (
        <section className='flex-1'>
            <div className='app-main-container py-8 px-10 max-w-[25rem] sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl flex flex-col gap-4 md:gap-8 lg:gap-10 2xl:gap-16'>
                <section>
                    <a href='/articles' className='flex flex-row items-center gap-4 app-animation-translate-y-start'>
                        <ArrowLeftIcon className='h-4 w-4 lg:h-6 xl:w-6' />
                        <p className='text-xs lg:text-sm 2xl:text-base'>Back to Previous Page</p>
                    </a>
                </section>

                <section>
                    <div className='py-4 md:py-0 lg:py-0 space-y-4 lg:space-y-6 2xl:space-y-8 app-animation-translate-y-start'>
                        <div>
                            <p className='text-sm lg:text-base 2xl:text-lg app-animation-translate-y-start'>{moment().format('DD MMMM YYYY')}</p>
                        </div>
                        <div>
                            <p className='text-2xl lg:text-4xl 2xl:text-5xl  font-bold text-indigo-600 app-animation-translate-y-start'>Five Ways That ESG Creates Value</p>
                        </div>
                        <div>
                            <p className='text-base lg:text-xl 2xl:text-2xl font-medium text-indigo-600 app-animation-translate-y-start'>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. </p>
                        </div>
                    </div>
                </section>


                <section>
                    <div className='text-center'>
                        <img
                            className='w-full aspect-h-[1/4] object-center md:object-center app-animation-scale-start'
                            src={ArticleItemDetail}
                            alt="item-1"
                        />
                    </div>
                </section>

                <section>
                    <div className='text-xs leading-relaxed lg:text-sm 2xl:text-base py-6 md:py-0 lg:py-0 text-justify space-y-4 app-animation-translate-y-start'>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac</p>
                    </div>
                </section>

                <section>
                    <div className='space-y-4 lg:space-y-8 app-animation-translate-y-start'>
                        <div>
                            <p className='text-sm lg:text-base'>SHARE TO</p>
                        </div>
                        <div>
                            <div className='flex gap-2 sm:gap-4 lg:gap-6'>
                                <div onClick={handleClipboard} className='cursor-pointer flex items-center justify-center p-3 lg:p-4 bg-black rounded-lg'>
                                    <ClipboardIcon />
                                </div>
                                <a href={instagramLink && instagramLink !== '' ? instagramLink : '/'} target="_blank" rel="noopener noreferrer">
                                    <InstagramIcon className="fill-white h-10 w-10 lg:h-12 lg:w-14" />
                                </a>
                                <a href={linkedinLink && linkedinLink !== '' ? linkedinLink : '/'} target="_blank" rel="noopener noreferrer">
                                    <LinkedinIcon className="fill-white h-10 w-10  lg:h-12 lg:w-14" />
                                </a>
                                <a href={twitterLink && twitterLink !== '' ? twitterLink : '/'} target="_blank" rel="noopener noreferrer">
                                    <TwitterIcon className="fill-white h-10 w-10  lg:h-12 lg:w-14" />
                                </a>
                                <a href={facebookLink && facebookLink !== '' ? facebookLink : '/'} target="_blank" rel="noopener noreferrer">
                                    <FacebookIcon className="fill-white h-10 w-10  lg:h-12 lg:w-14" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Newsletter />
        </section >
    )
}

export default ArticlesDetails
