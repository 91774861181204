import React from 'react'

function LocationIcon({
    className = "h-8 w-8"
}) {
    return (
        <svg className={className} viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16.2442C14.1455 16.2442 15.8848 14.5049 15.8848 12.3594C15.8848 10.2139 14.1455 8.47464 12 8.47464C9.85451 8.47464 8.11523 10.2139 8.11523 12.3594C8.11523 14.5049 9.85451 16.2442 12 16.2442Z" stroke="black" stroke-width="2.58986" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 2C9.25256 2 6.6176 3.09144 4.67483 5.03421C2.73206 6.97697 1.64063 9.61193 1.64062 12.3594C1.64063 14.8094 2.16119 16.4125 3.58302 18.1866L12 27.8986L20.4171 18.1866C21.8389 16.4125 22.3595 14.8094 22.3595 12.3594C22.3595 9.61193 21.268 6.97697 19.3253 5.03421C17.3825 3.09144 14.7475 2 12 2Z" stroke="black" stroke-width="2.58986" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default LocationIcon