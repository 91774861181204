import React from 'react'

function BurgerMenuIcon({
    className = "h-4 w-4 fill-white",
    stroke = "black"
}) {
    return (
        <svg className={className} viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H30M2 12H30M2 22H30" stroke={stroke} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default BurgerMenuIcon