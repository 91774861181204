import React from 'react'

function KeyboardArrowDownIcon({
    className = "h-3 w-3 fill-black",
}) {
    return (
        <svg
            className={className}
            viewBox="0 0 27 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.4207 0.579298C26.0497 0.208377 25.5465 4.6413e-06 25.0219 4.59544e-06C24.4972 4.54957e-06 23.9941 0.208377 23.623 0.579298L13.8292 10.3731L4.03538 0.579296C3.66222 0.218887 3.16243 0.0194605 2.64366 0.0239685C2.1249 0.0284764 1.62865 0.236558 1.26181 0.603397C0.894971 0.970236 0.686891 1.46648 0.682384 1.98525C0.677875 2.50402 0.877301 3.00381 1.23771 3.37697L12.4304 14.5696C12.8014 14.9406 13.3046 15.1489 13.8292 15.1489C14.3538 15.1489 14.857 14.9406 15.228 14.5696L26.4207 3.37697C26.7916 3.00594 27 2.50277 27 1.97813C27 1.45349 26.7916 0.950331 26.4207 0.579298Z"
            />
        </svg>
    )
}

export default KeyboardArrowDownIcon