import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { resources } from 'utils/constants/data'

import logo from 'assets/images/kadin-logo-web.png'
import SearchIcon from 'assets/icons/SearchIcon'
import BurgerMenuIcon from 'assets/icons/BurgerMenuIcon'

import useFetch from 'utils/hooks/useFetch';

function Navbar({
  openMenu,
  openSearchPage
}) {
  const [scrolled, setScrolled] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)
  const pathName = window.location.pathname
  const [searchQuery, setSearchQuery] = useState("")
  const storedLanguage = localStorage.getItem("language")
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  const handleChangeSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    if (storedLanguage === 'en') {
      setSelectedLanguage('en')
    }
    if (storedLanguage === 'id') {
      setSelectedLanguage('id')
    }
  }, [storedLanguage]);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  const searchResults = resources?.filter(item => item.title.includes(searchQuery))

  return (
    <>
      <div className="sticky top-0 left-0 right-0 bg-white shadow-md transition-all z-40">
        <div className="app-nav-container flex justify-between items-center md:gap-4 xl:gap-6 md:text-xs lg:text-sm xl:text-base">
          <div>
            <a href='/'>
              <img src={logo} alt="logo" className='h-14 sm:h-16 lg:h-18 xl:h-20 2xl:h-24 object-contain' />
            </a>
          </div>
          <div className={"hidden md:flex md:space-x-4 lg:space-x-12 text-black"}>
            <ul className='flex items-center space-x-4 lg:space-x-12'>
              <li className='hover:font-semibold'>
                <a href="/about-us">
                  {selectedLanguage === 'en' && 'About Us'}
                  {selectedLanguage === 'id' && 'Tentang Kami'}
                </a>
              </li>
              {/* <li className='hover:font-semibold'>
                                <a href="/our-members">Our Members</a>
                            </li> */}
              {/* <li className='hover:font-semibold'>
                <a href="/articles">Resources and Articles</a>
              </li> */}
              <li className='hover:font-semibold' >
                <a href="/contact-us">
                  {selectedLanguage === 'en' && 'Contact Us'}
                  {selectedLanguage === 'id' && 'Hubungi Kami'}
                </a>
              </li>
            </ul>
            {/* <div className='flex'>
                            <button onClick={() => setShowSearchBar(prev => !prev)}>
                                <SearchIcon className={`md:h-5 md:w-5 lg:h-6 lg:w-6 ${pathName === "/" ? `${scrolled ? "fill-white" : "fill-black"}` : "fill-white"}`} />
                            </button>
                        </div> */}
          </div>
          <div className='px-2 md:hidden space-x-8'>
            {/* <button
                            className='md:hidden active:border-b-2 active:border-black transition-all'
                            onClick={openSearchPage}
                        >
                            <SearchIcon className='h-5 w-5' />
                        </button> */}
            <button
              className=' md:hidden active:border-b-2 active:border-black transition-all'
              onClick={openMenu}
            >
              <BurgerMenuIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        {showSearchBar && (
          <div className={`hidden sm:block absolute left-0 right-0 bg-white shadow-md`}>
            <div className='bg-indigo-50 app-nav-container md:px-24 lg:px-40 space-y-8'>
              <div className='flex items-center border border-x-transparent border-t-transparent border-b-black bg-indigo-50 px-3 py-4 gap-4'>
                <button>
                  <SearchIcon className="h-3 sm:h-5 w-3 sm:w-5 fill-black" />
                </button>
                <input
                  className='flex-1 bg-indigo-50 outline-none text-xs sm:text-sm'
                  placeholder='Search...'
                  value={searchQuery}
                  onChange={handleChangeSearch}
                  autoFocus
                />
              </div>
              {searchQuery ? (
                <div className='bg-indigo-50 max-h-[40vh] overflow-y-auto flex flex-col gap-6 pb-5'>
                  {searchResults.map((item, i) => (
                    <a href={`/articles/${item.id}`} className='space-y-2'>
                      <p className='text-xs sm:text-sm font-bold'>{item.title}</p>
                      <p className='text-xs sm:text-sm'>{item.description}</p>
                    </a>
                  ))}
                </div>
              ) : (
                <div className='bg-indigo-50 text-xs sm:text-sm pb-5 flex items-center justify-center text-app-light-grey-2'>
                  No recent searches
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showSearchBar && (
        <div className='fixed top-0 bottom-0 right-0 left-0 z-30 bg-app-grey/50 blur-lg' />
      )}
    </>
  )
}

export default Navbar
