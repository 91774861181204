import React, { useState, useEffect } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import CloseIcon from 'assets/icons/CloseIcon';
import Member1 from 'assets/images/our-members/member-1.png';
import Banner3 from 'assets/images/landing-page/banner-3.png'
import TwitterIcon from 'assets/icons/social-media/TwitterIcon'
import FacebookIcon from 'assets/icons/social-media/FacebookIcon'
import InstagramIcon from 'assets/icons/social-media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social-media/LinkedinIcon'

function MemberDetail({
    setOpenMemberDetail
}) {
    const facebookLink = 'https://www.facebook.com'
    const twitterLink = 'https://www.twitter.com'
    const linkedinLink = 'https://www.linkedin.com'
    const instagramLink = 'https://www.instagram.com'

    const handleCancel = () => {
        setOpenMemberDetail(false)
    }

    return (
        <div className='fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-center'>
            <div className='flex flex-col rounded-xl fixed bg-white z-50 sm:mb-4 md:mb-20 mx-6 sm:mx-16 md:mx-20 lg:mx-0 max-w-xs sm:max-w-3xl max-h-[90vh] text-left py-4 pb-8 sm:px-6 sm:py-6 sm:pb-12 space-y-6 sm:space-y-8'>
                <div className='mt-2 mr-6 sm:mr-3 flex justify-end'>
                    <button onClick={handleCancel}><CloseIcon /></button>
                </div>
                <div className='flex flex-col sm:flex-row px-10 font-medium gap-3 sm:gap-8'>
                    <div className='flex items-start sm:justify-center'>
                        <img
                            src={Member1}
                            alt="item-1"
                            className='w-full max-h-[10rem] sm:max-w-[10rem] md:max-w-[13rem] object-cover sm:max-h-[20rem] rounded'
                        />
                    </div>
                    <div className='flex flex-col justify-center gap-0 sm:gap-2'>
                        <div className='mb-2 flex flex-row gap-4'>
                            <a href={linkedinLink && linkedinLink !== '' ? linkedinLink : '/'} target="_blank" rel="noopener noreferrer">
                                <LinkedinIcon className="fill-white h-8 sm:h-9 w-8 sm:w-10" />
                            </a>
                        </div>
                        <div>
                            <p className='text-sm text-indigo-600 font-bold sm:text-xl md:text-2xl'>
                                Brandon Lee
                            </p>
                        </div>
                        <div>
                            <p className='text-xs sm:text-base md:text-lg'>
                                President Director
                            </p>
                        </div>

                    </div>
                </div>
                <div className='px-10 space-y-4 max-h-52 sm:max-h-60 overflow-y-auto'>
                    <div className='text-xs text-justify sm:text-base '>
                        <p>Lorem ipsum dolor sit amet consectetur. Massa urna quis erat sit risus cursus feugiat ut. Hac at morbi mauris sodales facilisi pellentesque donec nibh. Amet amet lobortis mollis eget lorem eget varius fusce faucibus. Cursus et vel nulla id id tristique odio fringilla. Pulvinar augue aliquet interdum feugiat mattis vestibulum aliquam id.</p>
                        <br />
                        <p>Lorem ipsum dolor sit amet consectetur. Massa urna quis erat sit risus cursus feugiat ut. Hac at morbi mauris sodales facilisi pellentesque donec nibh. Amet amet lobortis mollis eget lorem eget varius fusce faucibus. Cursus et vel nulla id id tristique odio fringilla. Pulvinar augue aliquet interdum feugiat mattis vestibulum aliquam id.</p>
                    </div>
                    <div className='text-xs pt-2 sm:pt-6 sm:text-base font-bold'>
                        <p>Specialization:</p>
                    </div>
                    <div className='text-xs sm:text-base'>
                        <p>ESG, Sustainability, Global Markets, Emerging Economy.</p>
                    </div>
                </div>
            </div>
            <div className='fixed top-0 bottom-0 right-0 left-0 z-40 bg-app-grey/50 blur-lg' />
        </div>
    )
}

export default MemberDetail