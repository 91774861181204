import React from 'react'

function EmailIcon({
    className = "h-7 w-7 fill-white"
}) {
    return (
        <svg className={className} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0507812 2.58986C0.0507812 1.90298 0.32364 1.24424 0.809332 0.758551C1.29502 0.272859 1.95376 0 2.64064 0H23.3595C24.0464 0 24.7051 0.272859 25.1908 0.758551C25.6765 1.24424 25.9493 1.90298 25.9493 2.58986V18.129C25.9493 18.8159 25.6765 19.4746 25.1908 19.9603C24.7051 20.446 24.0464 20.7188 23.3595 20.7188H2.64064C1.95376 20.7188 1.29502 20.446 0.809332 19.9603C0.32364 19.4746 0.0507813 18.8159 0.0507812 18.129V2.58986ZM4.60763 2.58986L13.0001 9.93339L21.3925 2.58986H4.60763ZM23.3595 4.31082L13.8534 12.6294C13.6173 12.8363 13.314 12.9504 13.0001 12.9504C12.6861 12.9504 12.3828 12.8363 12.1467 12.6294L2.64064 4.31082V18.129H23.3595V4.31082Z" fill="black" />
        </svg>
    )
}

export default EmailIcon